// Translated
// Migrated
<template>
  <div class="relative w-full py-4 my-1 review-scroller">
    <Scroller
      v-if="reviews.length !== 0"
      class="flex flex-row overflow-hidden"
      :speed="10"
    >
      <nuxt-link
        v-for="(review, i) in reviews"
        :key="`r-${i}`"
        class="inline-flex flex-col pr-6 review-scroller-item"
        :to="`/${review?.url_slug}`"
      >
        <span class="truncate text-black">
          <div
            class="inline-flex mr-2"
          >
            <Stars
              :score="review?.rating"
              type="inline"
            />
          </div>
          <span>
            {{ review?.text }}
          </span>
        </span>
        <span class="truncate">
          {{ localeDateFormat(review?.created_at, 'YYYY/MM/DD') }} - {{ review?.Resnamn }}
        </span>
      </nuxt-link>
    </Scroller>
  </div>
</template>

<script setup>
defineProps({
  reviews: {
    type: Array,
    default: () => [],
  },
})

const { localeDateFormat } = useDate()
</script>

<style lang="scss" scoped>
.review-scroller {
  &::after {
    content: '';
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: linear-gradient(
      90deg,
      rgba(255,255,255,1) 0%,
      rgba(255,255,255,0) 5%,
      rgba(255,255,255,0) 95%,
      rgba(255,255,255,1) 100%
    );
  }

  &-item {
    max-width: 400px;
  }
}
</style>
